module.exports = [{
      plugin: require('/home/runner/work/walrus.cool/walrus.cool/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://walrus.cool"},
    },{
      plugin: require('/home/runner/work/walrus.cool/walrus.cool/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"walrus.cool","short_name":"walrus.cool","lang":"en","start_url":"/","background_color":"#fdf6e3","theme_color":"#007aff","display":"standalone","icon":"/home/runner/work/walrus.cool/walrus.cool/src/images/walrus.png"},
    },{
      plugin: require('/home/runner/work/walrus.cool/walrus.cool/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
